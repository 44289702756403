import React from "react";
import ButtonLoader from "./ButtonLoader";
import { getTruthyValue } from "@/utils";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: {
    label?: string;
    isLoading: boolean;
  };
  label: string;
  overrideClass?: string;
}

const CustomButton = ({ overrideClass, loading, label, ...props }: Props) => {
  const getClassName = () => {
    const defaultClass =
      "d-flex justify-content-center align-items-center no-wrap ";

    if (overrideClass) {
      return defaultClass + overrideClass;
    }

    const className = props?.className ?? "";
    return "btn btn-primary " + defaultClass + className;
  };

  const getText = () => {
    if (loading?.isLoading) {
      return getTruthyValue(loading?.label, label);
    }
    return label;
  };

  return (
    <button
      {...props}
      disabled={getTruthyValue(props?.disabled, loading?.isLoading)}
      className={getClassName()}
    >
      <span>{getText()}</span>
      {loading?.isLoading && (
        <span className="ms-2 d-flex align-items-center">
          <ButtonLoader />
        </span>
      )}
    </button>
  );
};

export default CustomButton;
