"use client";

import React, { useCallback, useEffect, useReducer } from "react";
import Image from "next/image";
import { getTruthyValue, isArrayIncludes } from "@/utils";
import CustomButton from "./CustomButton";
import CustomModal from "./CustomModal";

export enum AlertModalEvent {
  CUSTOM_ALERT_EVENT = "custom-alert",
}

export type AlertModalType =
  | "success"
  | "error"
  | "warning"
  | "loading"
  | "confirm";

export interface AlertModalState {
  isVisible: boolean;
  type: AlertModalType;
  showCloseIcon?: boolean;
  onCancel?: VoidFunction | null;
  onProceed?: VoidFunction | null;
  timer?: number | null;
  buttons?: {
    cancel?: string;
    proceed?: string;
  };
  content?: {
    title: string;
    message?: string;
  };
}

const initialState: AlertModalState = {
  isVisible: false,
  type: "success",
  showCloseIcon: false,
  onCancel: null,
  onProceed: null,
  content: {
    title: "",
    message: "",
  },
};

const DefaultAlert = () => {
  const [state, updateState] = useReducer(
    (state: AlertModalState, newState: Partial<AlertModalState>) => {
      return { ...state, ...newState };
    },
    initialState
  );

  const handleCancel = () => {
    const { onCancel } = state;
    updateState({
      isVisible: false,
      onCancel: null,
      onProceed: null,
    });
    onCancel?.();
  };

  const handleProceed = () => {
    const { onProceed } = state;
    updateState({
      isVisible: false,
      onCancel: null,
      onProceed: null,
    });
    onProceed?.();
  };

  const handleAlertEvent = useCallback((event: Event) => {
    const customEvent = event as CustomEvent<AlertModalState>;

    updateState({ ...customEvent?.detail });
  }, []);

  useEffect(() => {
    addEventListener(AlertModalEvent.CUSTOM_ALERT_EVENT, handleAlertEvent);

    return () => {
      removeEventListener(AlertModalEvent.CUSTOM_ALERT_EVENT, handleAlertEvent);
    };
  }, []);

  return (
    <CustomModal
      size="md"
      showCloseIcon={state?.showCloseIcon}
      showModal={state?.isVisible}
      onDismiss={handleCancel}
    >
      <div className="py-2">
        {isArrayIncludes<AlertModalType>(
          ["error", "warning", "success"],
          state?.type
        ) && (
          <div className="text-center">
            <Image
              hidden={state?.type !== "warning"}
              src="/ecg-pending.svg"
              alt="warning"
              width={65}
              height={65}
              className="mb-3"
            />

            <Image
              hidden={state?.type !== "error"}
              src="/x-circle.svg"
              alt="warning"
              width={65}
              height={65}
              className="mb-3"
            />

            <Image
              hidden={state?.type !== "success"}
              src="/solid-success.svg"
              alt="warning"
              width={65}
              height={65}
              className="mb-3"
            />
            <div className="mb-4 text-center">
              <h6 className="font-weight-bolder">{state?.content?.title}</h6>
              <div>
                <p>{state?.content?.message}</p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-lg-5">
                <div className="d-flex flex-column justify-content-center gap-3">
                  <CustomButton
                    className="px-4"
                    onClick={handleProceed}
                    label={getTruthyValue(state?.buttons?.proceed, "OK")}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {isArrayIncludes<AlertModalType>(["loading"], state?.type) && (
          <div className="text-center">
            <span
              className="spinner-border spinner-border-lg"
              style={{
                width: "4rem",
                height: "4rem",
                border: "0.4em solid rgba(0, 0, 0, 0)",
                borderRightColor: "#2E3192",
              }}
            />
            <div className="mt-2">
              {getTruthyValue(state?.content?.title, "Loading...")}
            </div>
          </div>
        )}

        {state?.type === "confirm" && (
          <div className="text-center">
            <Image
              src="/ecg-pending.svg"
              alt="warning"
              width={65}
              height={65}
              className="mb-3"
            />
            <div>
              <h6 className="font-weight-bold">{state?.content?.title}</h6>
              <p>{state?.content?.message}</p>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-lg-5">
                <div className="d-flex flex-column justify-content-center gap-3">
                  <CustomButton
                    className="px-3 px-md-5"
                    onClick={handleProceed}
                    label={getTruthyValue(state?.buttons?.proceed, "OK")}
                  />

                  <CustomButton
                    className="px-3 px-md-5"
                    onClick={handleCancel}
                    label={getTruthyValue(state?.buttons?.cancel, "Cancel")}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </CustomModal>
  );
};

export default DefaultAlert;
